<template>
  <div class="Serve">
    <div class="Serve_h">
      <van-tabs
        v-model="active"
        @click="onClick"
        color="blue"
        line-height="2px"
      >
        <van-tab
          :title="item"
          v-for="item in ['全部', '在线咨询', '提问']"
          :key="item"
        >
          <div class="Serve_list" v-for="item in list" :key="item.id">
            <h4 class="van-multi-ellipsis--l2">
              {{ item.question }}
            </h4>
            <div class="Serve_list_time">
              <p>
                <van-icon name="clock-o" /> 申请时间：{{
                  item.addTime.split("T")[0]
                }}
                发起申请
              </p>
              <span :class="item.status == 0 ? 'wait' : 'reply'">{{
                item.status == 0 ? "未处理" : "已回复"
              }}</span>
            </div>
            <p>
              <van-icon name="chat-o" />
              客服回复：已安排客服人员与您联系，请保持手机畅通
            </p>
          </div></van-tab
        >
      </van-tabs>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import { List, Icon, Tab, Tabs } from "vant";
import { findContactUs } from "@/api/ContactUs";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [List.name]: List,
    [Icon.name]: Icon,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    copyrightIp,
  },
  data() {
    return {
      active: "",
      list: [],
    };
  },
  mounted() {
    this.getFindContactUs();
  },
  methods: {
    getFindContactUs(formType = "") {
      let openId =
        localStorage.getItem("code") || "oE1PV5krMUmcVe6GLqHcKX8s-Tj8";
      findContactUs({ formType, openId }).then((res) => {
        this.list = res.data.data;
      });
    },
    onClick(name, title) {
      switch (title) {
        case "全部":
          this.getFindContactUs();
          break;
        case "在线咨询":
          this.getFindContactUs(0);
          break;
        case "提问":
          this.getFindContactUs(1);
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Serve {
  height: 100%;
  ::v-deep .van-tabs__content {
    border-top: 1px solid #e0dcdc;
  }
  .Serve_h {
    min-height: calc(100vh - 44px);
  }
}
.Serve_list {
  width: 100%;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  h4 {
    font-size: 16px;
  }
  .Serve_list_time {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 12px;

      padding: 1px 4px;
    }
    .reply {
      color: #3d8df7;
      background: #d8e8fd;
    }
    .wait {
      color: #ffbf42;
      background: #fff2d9;
    }
  }
  p {
    font-size: 12px;
    color: #aeacac;
  }
}
</style>
